import React from "react";
import Navbar from "../components/Navbar/Navbar";

const LandingPage: React.FunctionComponent = () => {
  return (
    <div className="min-h-screen h-fit w-full bg-red">
      <Navbar />
      <div className="h-screen flex text-white desktop:-mt-20">
        <div className="flex flex-col w-full desktop:flex-row items-center justify-center desktop:space-x-8">
          <div className="flex flex-col items-center desktop:items-end font-formula text-head desktop:text-xlhead">
            <h1>Mint</h1>
            <p className="block desktop:hidden text-medium font-base px-16 text-center pb-8">
              mrVroom is a collection of F1-inspired, randomly generated NFTs -
              which are released in limited quantities after each F1 weekend.
            </p>
            <h1>Compete</h1>
            <p className="block desktop:hidden text-medium font-base px-16 text-center pb-8">
              Each F1 weekend, mrVroom holds a competition. The better the
              driver you mint does in the actual F1 race, the better you do.
            </p>
            <h1>Earn</h1>
            <p className="block desktop:hidden text-medium font-base px-16 text-center pb-8">
              Our goal is to give away the majority of our earnins to our
              holders via competitions. Prizes will be between 50-90% mint
              sales.
            </p>
          </div>
          <div className="hidden desktop:block space-y-14 desktop:max-w-[500px]">
            <p>
              mrVroom is a collection of F1-inspired, randomly generated NFTs -
              which are released in limited quantities after each F1 weekend.
            </p>
            <p>
              Each F1 weekend, mrVroom holds a competition. The better the
              driver you mint does in the actual F1 race, the better you do.
            </p>
            <p>
              Our goal is to give away the majority of our earnins to our
              holders via competitions. Prizes will be between 50-90% mint
              sales.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
